import { Directive, ElementRef, inject, Renderer2 } from '@angular/core';
import { ZIndexService } from '@garmin-avcloud/avcloud-ui-common/shared';

@Directive({
  selector: '[flyToFrontOnInteract]',
  host: {
    '(mousedown)': 'setZIndex()',
    '(focusin)': 'setZIndex()',
  },
})
export class ToFrontOnInteractDirective {
  protected readonly renderer = inject(Renderer2);
  protected readonly zIndexService = inject(ZIndexService);
  protected readonly elementRef = inject(ElementRef);

  protected setZIndex(): void {
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'z-index',
      this.zIndexService.nextZIndex(),
    );
  }
}
